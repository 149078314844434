const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  setUserDocument(state, payload) {
    if (payload != null) {
      payload.subscriptions = {};
    }
    state.userDocument = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  setIsUserAdmin(state, payload) {
    state.isUserAdmin = payload;
  },
  setLoginStatus(state, payload) {
    state.loginStatus = payload;
  },
  setIsVerifying(state, payload) {
    state.isVerifying = payload;
  },
  setUserFirestoreListener(state, payload) {
    state.userFirestoreListener = payload;
  }
};

export default mutations;
