const getters = {
  getUser(state) {
    return state.user;
  },
  getUserDocument(state) {
    return state.userDocument;
  },
  getLoginStatus(state) {
    return state.loginStatus;
  },
  getError(state) {
    return state.error;
  },
  isUserAuth(state) {
    return !!state.user;
  },
  isUserAdmin(state) {
    return state.isUserAdmin;
  },
  isVerifying(state) {
    return state.isVerifying;
  }
};

export default getters;
