const parseGeocodeResponse = function(response) {
  const resultArray = {
    street: "",
    street_number: "",
    city: "",
    country: "",
    postal_code: ""
  };

  window._.each(response.address_components, component => {
    window._.each(component.types, type => {
      if (type === "route") {
        resultArray.street = component.long_name;
      }
      if (type === "street_number") {
        resultArray.street_number = component.long_name;
      }
      if (type === "postal_town") {
        resultArray.city = component.long_name;
      }
      if (type === "country") {
        resultArray.country = component.long_name;
      }
      if (type === "postal_code") {
        resultArray.postal_code = component.long_name;
      }
    });
  });

  return resultArray;
};

export default {
  data() {
    return {
      isFetchingAddress: false,
      googleService: null,
      addressResults: []
    };
  },
  mounted() {
    this.$loadScript(
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDdVhh6dCJw1aSbIRUbKFiGaUwEgkeCEo0&libraries=places"
    ).then(() => {
      this.googleService = new window.google.maps.places.AutocompleteService();
    });
  },
  methods: {
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.addressResults = [];
        return;
      }
      this.addressResults = predictions;
    },

    getAddressSuggestion(address) {
      if (address != "") {
        this.googleService.getPlacePredictions(
          {
            input: address,
            types: ["address"],
            componentRestrictions: { country: "no" }
          },
          this.displaySuggestions
        );
      } else {
        this.addressResults = [];
      }
    },

    updateAddress(prediction) {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ placeId: prediction.place_id }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            const result = parseGeocodeResponse(results[0]);

            // extract country code
            let adrComps = results[0]["address_components"];
            for (var i = 0; i < adrComps.length; i++) {
              if (adrComps[i].types[0] == "country") {
                this.profile.countryCode = adrComps[i].short_name;
              }
            }
            this.profile.address1 = `${result.street} ${result.street_number}`;
            this.profile.zip = result.postal_code;
            this.profile.city = result.city;
            this.addressResults = [];
          }
        }
      });
    }
  }
};
