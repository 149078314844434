import {
  required,
  confirmed,
  email,
  numeric,
  between,
  min,
  min_value,
  max
} from "vee-validate/dist/rules";
import { extend, localize, validate } from "vee-validate";
import nb_NO from "vee-validate/dist/locale/nb_NO.json";

import Axios from "axios";
import Vue from "vue";

localize("nb_NO", nb_NO);

// general fields

extend("required", {
  ...required,
  message: "Feltet er påkrevd"
});

extend("email", {
  ...email,
  message: "Må være en gyldig e-post"
});

extend("between", { ...between, message: "Må være mellom" });
extend("min", min);
extend("minBid", {
  ...min_value,
  message: "Bud må være minst {min},-"
});
extend("max", max);
extend("numeric", numeric);

extend("confirmed", {
  ...confirmed,
  message: "Passordfeltene må være like"
});

extend("bidOnly500exceptMinBid", {
  validate(value, { minBid, minRounded }) {
    minRounded = Math.round(value / 500) * 500;
    if (value == minBid || value == minRounded) {
      return true;
    } else {
      return false;
    }
    /*console.log((value = Math.floor(value / 500) * 500));
    console.log((value = Math.ceil(value / 500) * 500));*/
  },
  params: ["minBid", "minRounded"],

  message: (field, params) => {
    //console.log(+params.minRounded);
    if (params.minBid == params.minRounded) {
      return `Bud må være minst ${params.minBid},-`;
    } else {
      return `Bud må være minst ${params.minBid},- eller ${params.minRounded},-`;
    }
  }
});

extend("customBetween", {
  async validate(value, { min, max }) {
    value = value.toString().replace(/[^0-9]+/g, "");
    const res = await validate(value, `between:${min},${max}`);
    return res.valid;
  },
  params: ["min", "max"],
  message: "Gyldig verdi må være mellom {min} kr og {max} kr"
});

extend("customBetweenYears", {
  ...between,
  message: "Gyldig verdi må være mellom {min} år og {max} år"
});

// only validates norwegian format.
// let users type what the fuck they want
// this validation method is currently not being used
extend("validateOrgNumber", {
  validate(value) {
    const pattern = /^\d{9}$/;
    if (!pattern.test(value)) {
      return false;
    }

    const numbers = String(value)
      .split("")
      .map(value => Number(value));
    const weights = [3, 2, 7, 6, 5, 4, 3, 2];
    const products = weights.map((value, index) => numbers[index] * value);
    const sum = products.reduce((acc, product) => acc + product, 0);

    const mod11 = 11;
    const rem = sum % mod11;
    const cn = rem === 0 ? 0 : mod11 - rem;

    if (cn >= 10) {
      return false;
    }

    return true;
  },
  message: "Ikke et gyldig organisasjonsnummer"
});

extend("orgnr_exists", {
  validate(value) {
    if (value.length == 9) {
      return Axios.get(
        `${window.apiURL}/profile/companies/lookup/${value}`
      ).then(response => {
        if (response.data && response.data.organizationNumber) {
          return false;
        } else {
          return true;
        }
      });
    }
  },
  message: "Organisasjonen er allerede registrert!"
});

extend("validPostCode", {
  validate(value, args) {
    return Axios.post(
      `${Vue.prototype.$axios.url}/lookup/bring`,
      {
        cc: args.country,
        pnr: "1337"
      },
      Vue.prototype.$axios.config
    )
      .then(() => {
        return true;
      })
      .catch(error => {
        return error.response.data.message;
      });
  },

  params: ["country", "postcode", "currentPostCode"]
});

// we actually dont need this, but nice to use for future reference
extend("sameEmail", {
  validate(value, args) {
    if (
      !args.verificationSent &&
      !args.emailVerified &&
      args.currentEmail == value
    ) {
      return false;
    } else {
      return true;
    }
  },
  message: "E-post er den samme",
  params: ["verificationSent", "emailVerified", "currentEmail"]
});
