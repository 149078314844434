import dayjs from "dayjs";
import moment from "moment-timezone";
import _ from "lodash";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale"
import isTodayPlug from "dayjs/plugin/isToday";
import generateUniqueId from "generate-unique-id";
import pathFunc from "path";

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(isTodayPlug);

dayjs.locale("nb");
moment.locale("nb");

dayjs.updateLocale("nb", {
  relativeTime: {
    future: "om %s",
    past: "for %s siden",
    s: "få sekunder",
    m: "ett minutt",
    mm: "%d minutter",
    h: "en time",
    hh: "%d timer",
    d: "en dag",
    dd: "%d dager",
    M: "en mnd.",
    MM: "%d mnd.",
    y: "ett år",
    yy: "%d år"
  }
});

const isDate = function(value) {
  if(value?.seconds || value?._seconds) {
    return true;
  }
  const regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)$/;
  return regex.test(value);
}

const formatNumber = function(number) {
  const formatter = new Intl.NumberFormat('nb-NO', {
    minimumFractionDigits: 0,
    useGrouping: true,
    currencyDisplay: 'symbol',
    currency: 'NOK',
  });

  return formatter.format(number).replace(/,/g, ' ');
}

// numbers to words
import writtenNumber from 'written-number';
writtenNumber.defaults.lang = 'no';

const numberToWords = function(number) {
  return writtenNumber(number, { lang: {
    "useLongScale": false,
    "baseSeparator": "-",
    "unitSeparator": "og ",
    "base": {
      "0": "null",
      "1": "ett",
      "2": "to",
      "3": "tre",
      "4": "fire",
      "5": "fem",
      "6": "seks",
      "7": "syv",
      "8": "åtte",
      "9": "ni",
      "10": "ti",
      "11": "elleve",
      "12": "tolv",
      "13": "tretten",
      "14": "fjorten",
      "15": "femten",
      "16": "seksten",
      "17": "sytten",
      "18": "atten",
      "19": "nitten",
      "20": "tjue",
      "30": "tretti",
      "40": "førti",
      "50": "femti",
      "60": "seksti",
      "70": "sytti",
      "80": "åtti",
      "90": "nitti"
      },
      "units" : [
      "hundre",
      "tusen",
      "million",
      "milliard",
      "billion",
      "kvadrillion",
      "kvintillion",
      "sextillion",
      "septillion",
      "oktillion",
      "nonillion",
      "desillion",
      "undesillion",
      "dodesillion",
      "tredesillion",
      "kvattordesillion",
      "kvindesillion"
      ],
    "unitExceptions": []
  } });
};


const isToday = function(dateTime) {
  return dayjs(dateTime).isToday();
};
const getRelativeTime = function(dateTime) {
  if (dateTime > new Date()) {
    return dayjs().to(dateTime);
  } else {
    return dayjs(dateTime).fromNow();
  }
};

const firebaseMessage = function(error) {
  switch (error.code) {
    case "auth/user-not-found":
      return "Denne e-postadressen finnes ikke i vårt system. Vær sikker på at adressen er skrevet inn riktig og prøv på nytt.\n Ta kontakt med oss dersom problemet vedvarer.";
    case "auth/wrong-password":
      return "Feil passord. Kontroller passordet og prøv igjen. Skulle problemet vedvare, kan du klikke på glemt passord nedenfor.";
    case "auth/email-already-in-use":
      return "Denne e-postadressen finnes allerede i vårt system. Prøv igjen eller bruk glemt passord-funksjonen.";
    default:
      return error.message;
  }
};

// restructure? this is really used as a mixin

const serializeUrl = function(parameters) {
  return Object.entries(parameters)
    .map(([key, value]) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");
};

const categoriesProps = function(item, prop) {
  // we show two props in the item boxes.
  let props = [];

  switch (item.category) {
    case "Konkursbo":
      props = ["subCategory", "location"];
      break;
    case "Bygg og anlegg":
      props = ["subCategory", "year"];
      break;
    case "Møbler / innbo / løsøre":
      props = ["subCategory", "year"];
      break;

    default:
      props = ["year", "mileage"];
      break;
  }

  let res = _.get(item, props[prop - 1], "");

  // dont return 0 values for year
  if (res === 0 && props[prop - 1] === "year") {
    return "";
  } else if (props[prop - 1] === "year") {
    if (parseInt(res) <= 9) {
      return String(res).padStart(2, "0");
    } else {
      return res;
    }
  }

  if (props[prop - 1] === "mileage") {
    if (res === "null" || res === 0 || res === "0" || !res) {
      return "";
    } else {
      res += " km";
    }
  }

  return res;
};

const mediaPath = function(path) {
  return `https://cdn.stadssalg.no/?path=${pathFunc.dirname(
    path
  )}&file=${encodeURIComponent(pathFunc.basename(path))}`;
};

const mediaPathResized = function(path, options) {
  if (path != null) {
    let optn = new URLSearchParams(options).toString();

    return `https://cdn.stadssalg.no/?${optn}&path=${pathFunc.dirname(
      path
    )}&file=${encodeURIComponent(pathFunc.basename(path))}`;
  } else {
    return "";
  }
};

const disableAutofill = function() {
  return generateUniqueId(10);
};

const getFormattedDateTime = function(dateTime, format) {
  // if firestore date, convert it
  if (dateTime?.seconds) {
    dateTime = new Date(dateTime.seconds * 1000);
  }

  if (dateTime?._seconds) {
    dateTime = new Date(dateTime._seconds * 1000);
  }

  const date = new Date(dateTime);

  if (date.toString() !== "Invalid Date") {
    dateTime = date;
  }

  if(format === "LL")  {
    format = 'DD.MM.YYYY';
  }

  return moment.unix(dateTime / 1000).tz('Europe/Oslo').format(format);
};

const validIdLookup = function(q) {
  if (!isNaN(q) && q.length == 5) {
    return true;
  } else {
    return false;
  }
};

const errorImage = function(event) {
  event.target.src = "https://media.stadssalg.no/404.png";
};

const translateObjectKeys = function (object) {
  // if object is not a string, translate every key in the object
  if(typeof object !== "string") {
    let translatedObject = {};
    for (const [key, value] of Object.entries(object)) {
      translatedObject[this.$t(`items.${key}`)] = value;
    }
    return translatedObject;
  } else {
    return object;
  }
};

const isString = function(value) {
  return typeof value === 'string';
}

const fetchOrg = async function(orgNr) {
  if (this.company.organizationNumber) {
    this.$axios
      .get(
        `https://data.brreg.no/enhetsregisteret/api/enheter?organisasjonsnummer=${orgNr}`
      )
      .then(response => {
        if (response.data._embedded && response.data._embedded.enheter) {
          let data = response.data._embedded.enheter[0];

          if (data.organisasjonsform) {
            this.company.organizationIndustry =
              data.organisasjonsform.beskrivelse;
          }

          if (data.naeringskode1) {
            this.company.organizationType = data.naeringskode1.beskrivelse;
          }

          this.company.address1 = data.forretningsadresse.adresse[0];

          if (data.forretningsadresse.adresse[1]) {
            this.company.address2 = data.forretningsadresse.adresse[1];
          }

          this.company.name = data.navn;
          this.company.countryCode = data.forretningsadresse.landkode;

          this.company.zip = data.forretningsadresse.postnummer;
          this.company.city = data.forretningsadresse.poststed;

          this.$buefy.toast.open({
            message: "Hentet bedriftsopplysninger",
            type: "is-success"
          });
        } else {
          this.$buefy.toast.open({
            message: "Kunne ikke hente bedriftsinfo",
            type: "is-danger"
          });
        }
      })
      .catch(error => {
        this.$buefy.toast.open({
          message: error.message,
          type: "is-danger"
        });
      });
  }
};

export default {
  methods: {
    categoriesProps,
    serializeUrl,
    mediaPath,
    mediaPathResized,
    validIdLookup,
    getFormattedDateTime,
    fetchOrg,
    errorImage,
    getRelativeTime,
    isToday,
    firebaseMessage,
    disableAutofill,
    numberToWords,
    formatNumber,
    isDate,
    translateObjectKeys,
    isString
  }
};
