import firebase from "firebase/compat/app";
import axios from "axios";

const actions = {
  signUpAction({ commit }, payload) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(payload.email, payload.password)
        .then(response => {
          response.user
            .sendEmailVerification()
            .then(() => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(error => {
          commit("setError", error.message);
          reject(error);
        });
    });
  },

  signInAction({ commit }, payload) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(async response => {
          commit("setUser", response.user);
          commit(
            "setUserDocument",
            (
              await axios.get(`${window.apiURL}/profile`, {
                userId: response.user.uid
              })
            ).data
          );
          commit("setLoginStatus", "loggedIn");
          resolve(response);
        })
        .catch(error => {
          commit("setError", error.message);
          reject(error);
        });
    });
  },

  forgotPasswordAction(_, payload) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(payload.email)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  signOutAction({ commit }) {
    firebase
      .auth()
      .signOut()
      .then(() => {})
      .catch(error => {
        commit("setError", error.message);
      });
  },

  authAction({ commit, state }) {
    const loginTimeout = setTimeout(() => {
      commit("setLoginStatus", "loggedOut");
    }, 4000);

    commit("setLoginStatus", "loggingIn");

    firebase.auth().onAuthStateChanged(async user => {
      clearInterval(loginTimeout);

      if (user) {
        const userClaims = (await user.getIdTokenResult()).claims;

        try {
          const userDocument = (
            await axios.get(`${window.apiURL}/profile`, {
              userId: user.uid
            })
          ).data;

          if (state.isVerifying) {
            await this.$axios.post(`${window.apiURL}/profile/verify`, {});
            commit("setIsVerifying", false);
          }

          commit("setUser", user);
          commit("setUserDocument", userDocument);
          commit("setError", null);
          commit("setIsUserAdmin", userClaims.role == "admin");
          commit("setLoginStatus", "loggedIn");
        } catch (e) {
          await new Promise(resolve => setTimeout(resolve, 5000));
          const userDocument = await axios.get(`${window.apiURL}/profile`, {
            userId: user.uid
          });

          if (state.isVerifying) {
            await this.$axios.post(`${window.apiURL}/profile/verify`, {});
            commit("setIsVerifying", false);
          }

          commit("setUser", user);
          commit("setUserDocument", userDocument);
          commit("setError", null);
          commit("setIsUserAdmin", userClaims.role == "admin");
          commit("setLoginStatus", "loggedIn");
        }
      } else {
        commit("setUser", null);
        commit("setUserDocument", null);
        commit("setError", null);
        commit("setIsUserAdmin", false);
        commit("setLoginStatus", "loggedOut");
      }
    });
  },

  setIsVerifying({ commit }, payload) {
    commit("setIsVerifying", payload);
  },

  clearData({ commit }) {
    commit("setUser", null);
    commit("setUserDocument", null);
    commit("setError", null);
  }
};

export default actions;
