import Vue from "vue";
import VueRouter from "vue-router";

import modalMixin from "../mixins/modal";
import store from "../store/index";

Vue.use(VueRouter);
Vue.use(modalMixin);

const titlePrefix = "Stadssalg AS - ";

const routes = [
  {
    path: "/error",
    name: "Error",
    component: () =>
      import("../views/ErrorPage.vue")
  },
  {
    path: "/financing/:item?",
    name: "Financing",
    component: () =>
      import("../views/financing/Index.vue"),
    meta: { title: "Finansering og forsikring" }
  },
  {
    path: "/",
    name: "Home",
    component: () =>
      import("../views/home/Index.vue"),
    meta: { title: "Stadssalg AS" }
  },
  {
    path: "/items",
    name: "Items",
    component: () =>
      import("../views/items/Index.vue"),
    meta: { title: titlePrefix + "Auksjoner" }
  },
  {
    path: "/items/:item",
    name: "Item",
    component: () =>
      import("../views/items/Item.vue"),
    meta: { title: titlePrefix + "Objekt" }
  },
  {
    path: "/item/:item",
    name: "Item",
    component: () =>
      import("../views/items/Item.vue"),
    meta: { title: titlePrefix + "Objekt" }
  },
  {
    path: "/info/:page?",
    name: "Info",
    meta: { title: titlePrefix + "Info" },
    component: () =>
      import("../views/info/Index.vue")
  },
  {
    path: "/no/personvern",
    redirect: { path: "/info/personvern" }
  },

  {
    path: "/loginWithToken/:token",
    name: "Login",
    component: () =>
      import("../views/user/LoginWithToken.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import("../views/user/Index.vue"),
    meta: { disableIfLoggedIn: true }
  },
  {
    path: "/registrer",
    name: "Register",
    component: () =>
      import("../views/user/Register.vue"),
    meta: { disableIfLoggedIn: true }
  },

  {
    path: "/registrer/privat",
    name: "RegisterPrivate",
    props: true,
    component: () =>
      import(
        "../views/user/register/Form.vue"
      ),
    meta: { disableIfLoggedIn: true }
  },

  {
    path: "/registrer/bedrift",
    name: "RegisterCompany",
    props: true,
    component: () =>
      import(
        "../views/user/register/Form.vue"
      ),
    meta: { disableIfLoggedIn: true }
  },

  {
    path: "/profil",
    name: "Profile",
    component: () =>
      import("../views/user/Index.vue"),
    meta: { requiresAuth: false }
  },
  {
    path: "/profil/mine-favoritter",
    name: "MyFavorites",
    component: () =>
      import(
        "../views/user/pages/MyFavorites.vue"
      ),
    meta: { requiresAuth: true }
  },
  {
    path: "/profil/mine-bud",
    name: "MyBids",
    component: () =>
      import("../views/user/pages/MyBids.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/profil/mine-innstillinger",
    name: "MySettings",
    component: () =>
      import("../views/user/pages/MySettings.vue"),
    meta: { requiresAuth: true }
  },
  {
    path: "/profil/mine-bedrifter/ny",
    name: "NewCompany",
    component: () =>
      import(
        "../views/user/pages/EditCompany.vue"
      ),
    meta: { requiresAuth: true }
  },
  {
    path: "/profil/mine-bedrifter/:companyId",
    name: "EditCompany",
    component: () =>
      import(
        "../views/user/pages/EditCompany.vue"
      ),
    meta: { requiresAuth: true }
  },
  {
    path: "/profil/lagrede-sok",
    name: "MyFilters",
    component: () =>
      import(
        "../views/user/pages/MyFilters.vue"
      ),
    meta: { requiresAuth: true }
  },
  {
    path: "/profil/mgmt",
    name: "ProfileMgmt",
    component: () =>
      import(
        "../views/user/CustomEmailHandler.vue"
      ),
    meta: { requiresAuth: false }
  },
  {
    path: "/404",
    component: () => import("../components/404.vue")
  },
  { path: "/:catchAll(.*)", alias: "*", redirect: "/error" }
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    return new Promise(resolve => {
      let timeout = 250;
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          timeout = 0;
          resolve({ top: 0, x: 0, y: 0 });
        }
      }, timeout);
    });
  },
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Stadssalg";

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const disableIfLoggedIn = to.matched.some(
    record => record.meta.disableIfLoggedIn
  );

  if (requiresAuth || disableIfLoggedIn) {
    const loginInterval = setInterval(() => {
      if (disableIfLoggedIn && store.getters.getLoginStatus == "loggedIn") {
        clearInterval(loginInterval);
        next();
        /*next({
          name: "Profile"
        });*/
      } else if (disableIfLoggedIn) {
        next();
      }

      if (requiresAuth) {
        if (store.getters.getLoginStatus == "loggedIn") {
          clearInterval(loginInterval);
          next();
        } else if (store.getters.getLoginStatus == "loggedOut") {
          clearInterval(loginInterval);
          next({
            name: "Profile",
            query: { nextUrl: to.fullPath }
          });
        }
      }
    }, 50);
  } else {
    next();
  }
});

export default router;
