<template slot="end">
  <div>
    <div v-if="getLoginStatus != 'loggingIn'">
      <b-navbar-dropdown v-if="isUserAuth" class="userMenu">
        <template slot="label">
          <b-icon pack="fas" class="mr-1" icon="user" size="is-small"></b-icon>
          {{ getUser.email }}
        </template>
        <b-navbar-item tag="router-link" :to="{ name: 'Profile' }">
          Min side
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'MyFilters' }">
          Lagrede søk
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'MyBids' }">
          Mine bud
        </b-navbar-item>

        <b-navbar-item
          class="my-4"
          tag="router-link"
          :to="{ name: 'MySettings' }"
        >
          Mine innstillinger
          <b-tag
            class="ml-2"
            v-if="new Date() < new Date('2024-07-01')"
            type="is-warning"
            >ny!</b-tag
          >
        </b-navbar-item>

        <!--<b-navbar-item tag="router-link" :to="{ name: 'MyFavorites' }">
          Mine favoritter
        </b-navbar-item>-->

        <b-navbar-item v-if="isUserAdmin" tag="router-link" to="/admin">
          Admin
        </b-navbar-item>
        <b-navbar-item class="mt-4" @click="signOut">
          Logg ut
        </b-navbar-item>
      </b-navbar-dropdown>

      <div class="buttons is-centered" v-else>
        <router-link :to="{ name: 'Register' }">
          <b-button class="button is-primary">
            <strong>Registrer</strong>
          </b-button>
        </router-link>

        <button class="button is-light" @click="openLoginModal()">
          Logg inn
        </button>
      </div>
    </div>
    <vue-bottom-sheet-vue2
      :click-to-close="true"
      :is-full-screen="true"
      :overlay="true"
      :rounded="false"
      ref="sheet"
    >
      <test v-bind:is="sheetComp"></test>
      {{ sheetComp }}
    </vue-bottom-sheet-vue2>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Login from "../user/Login";
import modalMixin from "../../mixins/modal";

export default {
  mixins: [modalMixin],
  components: { Login },
  data() {
    return {
      sheetComp: "Login"
    };
  },
  computed: {
    ...mapGetters(["isUserAuth", "isUserAdmin", "getLoginStatus", "getUser"])
  },
  methods: {
    ...mapActions(["signOutAction"]),

    openLoginModal() {
      this.openModal({ component: Login, props: { name: "Login" } });
    },

    signOut() {
      this.signOutAction();

      if (
        !(
          this.$router.currentRoute.fullPath.startsWith("/items") ||
          this.$router.currentRoute.fullPath.startsWith("/financing") ||
          this.$router.currentRoute.fullPath.startsWith("/info") ||
          this.$router.currentRoute.fullPath == "/"
        )
      ) {
        this.$router.push({
          name: "Home"
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: $desktop) {
  .buttons,
  .userMenu {
    margin-top: 3em;
  }
  .modalPoppin {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
  }
}
</style>
