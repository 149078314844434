import Vue from "vue";
import VueI18n from "vue-i18n";
import nb from '@/locales/nb.json';
import en from '@/locales/nb.json';

Vue.use(VueI18n);

export default new VueI18n({
  locale: import.meta.env.VITE_I18N_LOCALE || "nb",
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || "en",
  messages: {
    nb: nb,
    en: en
  }
});
