import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    userDocument: null,
    error: null,
    isUserAdmin: false,
    isVerifying: false,
    loginStatus: "init",
    userFirestoreListener: null
  },
  getters: getters,
  mutations: mutations,
  actions: actions
});

/*
  STATUSER I "loginStatus":

  init - Den har startet opp
  loggingIn - Den venter på svar fra Firebase
  loggedIn - Logget inn hos Firebase
  loggedOut - Logget ut hos Firebase
*/
