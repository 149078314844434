import isMobile from "mobile-device-detect";
export default {
  data() {
    return {
      sheetComp: null
    };
  },
  methods: {
    openModal: function(options) {
      /* remove this to ensure modal is always opened
      if (isMobile.isMobile) {
        if (this.$refs.sheet && options.props && options.props.name) {
          this.sheetComp = options.props.name;
          this.$refs.sheet.open();
        }
      } else { */
        this.$buefy.modal.open(
          Object.assign(
            {
              parent: this,
              hasModalCard: true,
              trapFocus: true,
              destroyOnHide: true,
              ariaRole: "dialog",
              ariaModal: true,
              fullScreen: isMobile.isMobile ? true : false
            },
            options
          )
        );
    }
  }
};
