<template>
  <div id="app">
    <b-navbar
      type="is-primary"
      :shadow="true"
      :spaced="true"
      :fixed-top="true"
      wrapper-class="container"
    >
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ name: 'Home' }">
          <img
            class="tmplogo"
            src="./assets/stadssalg.png"
            alt="Stadssalg AS"
          />
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item tag="router-link" :to="{ name: 'Items' }">
          Auksjoner
        </b-navbar-item>

        <b-navbar-item tag="router-link" :to="{ path: '/info/kundeservice' }">
          Kundeservice
        </b-navbar-item>

        <!-- 
        <b-navbar-item tag="router-link" :to="{ name: 'Financing' }">
          <b-icon icon="star" class="mr-1" /> Finansering og forsikring
        </b-navbar-item>
        -->
      </template>

      <template slot="end">
        <user-menu></user-menu>
      </template>
    </b-navbar>

    <div class="container-wrapper" id="container">
      <transition name="fade" mode="out-in" @after-leave="afterLeave">
        <router-view :key="$route.fullPath" />
      </transition>
    </div>

    <footer id="main-footer">
      <div class="columns">
        <div class="column is-3">
          <h2 class="">Stadssalg AS</h2>
          <p class="bd-notification is-primary">
            Adresse: Olav V gate 100<br />
            8004 Bodø
          </p>
          <p class="mt-2">
            <router-link :to="{ path: '/info/kundeservice' }"
              >Kundeservice</router-link
            >
          </p>
        </div>
        <div class="column is-3">
          <img class="mb-3" src="@/assets/img/miljofyrtarn.webp" />
        </div>
        <div class="column is-3">
          <h2 class="">Hurtiglinker</h2>
          <ul>
            <li>
              <router-link :to="{ path: '/info/budsystemet' }"
                >Budsystem og vilkår</router-link
              >
            </li>
            <li>
              <router-link :to="{ path: '/info/personvern' }"
                >Personvern</router-link
              >
            </li>
            <li>
              <router-link :to="{ path: '/info/aapenhetsloven' }"
                >Åpenhetsloven</router-link
              >
            </li>
          </ul>
        </div>
        <div class="column is-3">
          <div
            class="fb-page"
            data-href="https://www.facebook.com/Stadssalg"
            data-width="340"
            data-adapt-container-width="true"
            data-hide-cover="false"
            data-show-facepile="true"
          ></div>
        </div>
      </div>
      <div class="columns is-vcentered footer-split is-mobile">
        <div class="column">
          <img
            class="tmplogo"
            src="./assets/stadssalg.png"
            alt="Stadssalg AS"
          />
        </div>

        <div class="column has-text-right">
          (c) {{ new Date().getFullYear() }} SS
        </div>
      </div>
    </footer>
    <reload-prompt></reload-prompt>
  </div>
</template>

<script>
import UserMenu from "./views/user/UserMenu";
import { mapActions } from "vuex";
import ReloadPrompt from "@/components/ReloadPrompt";

import "vanilla-cookieconsent/dist/cookieconsent.js";
import "vanilla-cookieconsent/dist/cookieconsent.css";

export default {
  components: { "user-menu": UserMenu, ReloadPrompt },

  data() {
    return {};
  },

  methods: {
    ...mapActions(["authAction"]),
    afterLeave() {
      this.$root.$emit("triggerScroll");
    }
  },

  created() {
    // obtain plugin
    var cc = window.initCookieConsent();

    cc.run({
      gui_options: {},
      current_lang: "no",
      autoclear_cookies: true, // default: false
      page_scripts: true, // default: false

      // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
      // delay: 0,                               // default: 0
      // auto_language: '',                      // default: null; could also be 'browser' or 'document'
      // autorun: true,                          // default: true
      // force_consent: false,                   // default: false
      // hide_from_bots: false,                  // default: false
      // remove_cookie_tables: false             // default: false
      cookie_name: "ss_kake", // default: 'cc_cookie'
      cookie_expiration: 182, // default: 182 (days)
      cookie_necessary_only_expiration: 182, // default: disabled
      cookie_domain: location.hostname, // default: current domain
      // cookie_path: '/',                       // default: root
      // cookie_same_site: 'Lax',                // default: 'Lax'
      // use_rfc_cookie: false,                  // default: false
      revision: 1, // default: 0

      languages: {
        no: {
          consent_modal: {
            title: "Vi bruker cookies!",
            description:
              'Stadssalg.no bruker cookies for å forbedre brukeropplevelsen din. En cookie er en liten tekstfil som lagres i nettleseren din, og som gjør det mulig for oss å huske deg når du besøker siden igjen. Du kan endre innstillingene for cookies i nettleseren din, men dette kan påvirke hvordan nettsiden fungerer. <button type="button" data-cc="c-settings" class="cc-link">La meg velge</button>',
            primary_btn: {
              text: "Godta alle",
              role: "accept_all" // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: "Avslå alle",
              role: "accept_necessary" // 'settings' or 'accept_necessary'
            }
          },
          settings_modal: {
            title: "Innstillinger for cookies",
            save_settings_btn: "Lagre innstillinger",
            accept_all_btn: "Godta alle",
            reject_all_btn: "Avslå alle",
            close_btn_label: "Lukk",
            cookie_table_headers: [
              { col1: "Navn" },
              { col2: "Domene" },
              { col3: "Utløpsdato" },
              { col4: "Beskrivelse" }
            ],
            blocks: [
              {
                title: "Bruk av informasjonskapsler",
                description:
                  'På denne nettsiden bruker vi cookies for å forbedre brukeropplevelsen din. En cookie er en liten tekstfil som lagres i nettleseren din, og som gjør det mulig for oss å huske deg når du besøker siden igjen. Du kan velge hvilke cookies to ønsker å gi tillatelse til. For mer informasjon, les <a href="https://www.stadssalg.no/info/personvern" class="cc-link">våre vilkår</a>.'
              },
              {
                title: "Nødvendige informasjonskapsler",
                description:
                  "Nødvendige informasjonskapsler bidrar til å gjøre vårt nettsted brukbart ved grunnleggende funksjoner som sidenavigasjon og tilgang til sikre områder av nettstedet. Nettstedet kan ikke fungere optimalt uten disse informasjonskapslene.",
                toggle: {
                  value: "necessary",
                  enabled: true,
                  readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                }
              },
              /*
              {
                title: "Performance and Analytics cookies",
                description:
                  "These cookies allow the website to remember the choices you have made in the past",
                toggle: {
                  value: "analytics", // your cookie category
                  enabled: false,
                  readonly: false
                },
                cookie_table: [
                  // list of all expected cookies
                  {
                    col1: "^_ga", // match all cookies starting with "_ga"
                    col2: "google.com",
                    col3: "2 years",
                    col4: "description ...",
                    is_regex: true
                  },
                  {
                    col1: "_gid",
                    col2: "google.com",
                    col3: "1 day",
                    col4: "description ..."
                  }
                ]
              },*/
              {
                title: "Informasjonskapsler for annonsering og målretting",
                description:
                  "Disse informasjonskapslene samler inn informasjon om hvordan du bruker nettsiden, hvilke sider du har besøkt og hvilke lenker du har klikket på. Alt data er anonymisert og kan ikke brukes til å identifisere deg",
                toggle: {
                  value: "targeting",
                  enabled: false,
                  readonly: false
                }
              },
              {
                title: "Mer informasjon",
                description:
                  'For spørsmål, gjerne <a class="cc-link" href="mailto:sporsmal@stadssalg.no">kontakt oss</a>.'
              }
            ]
          }
        }
      },
      onAccept: function() {
        console.log(cc.allowedCategory("targeting"));
        if (cc.allowedCategory("targeting")) {
          // fb init
          (function(d) {
            var js,
              id = "facebook-jssdk";
            if (d.getElementById(id)) {
              return;
            }
            js = d.createElement("script");
            js.id = id;
            js.async = true;
            js.src =
              "https://connect.facebook.net/nb_NO/sdk.js#xfbml=1&version=v15.0&appId=190661696694&autoLogAppEvents=1";
            d.getElementsByTagName("head")[0].appendChild(js);
          })(document);
        }
      }
    });
  },

  mounted() {
    this.authAction();
  }
};
</script>

<style lang="scss">
@media screen and (min-width: $desktop) {
  .navbar-wrapper {
    margin: 0 auto;
    width: $desktop;
    display: flex;
  }
}

#app {
  display: flex;
  height: calc(100vh - (#{$navbar-height} + 2em));
  flex-direction: column;

  @media screen and (max-width: $desktop) {
    height: calc(100vh - #{$navbar-height});
  }
}

#cc_div {
  .title {
    font-size: 1rem;
  }
}

.container-wrapper {
  flex: 1;
  padding-top: 1em;
}

footer#main-footer {
  padding: 2em;
  color: #fff;
  background: $primary;
  min-height: 8em;
  margin-top: 3em;
  flex-shrink: 0;

  a {
    &:hover {
      color: white;
    }
  }

  .footer-split {
    border-top: 1px solid #aaa;
    padding: 1em 0;
  }

  .columns {
    margin: 0 auto;
    font-size: 11pt;
    max-width: $fullhd;

    h2 {
      font-size: 14pt;
      margin-bottom: 0.8em;
    }

    .tmplogo {
      height: 2.75em;
    }
  }
}

.tmplogo {
  max-height: 2.75em !important;
  /*filter: brightness(0) invert(1);*/
}
</style>
