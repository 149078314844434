<template>
  <div class="modal-card loginModal" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Glemt passord</p>
    </header>
    <form @submit.prevent="pressed">
      <section>
        <article v-if="error" class="message is-danger">
          <div class="message-body">
            {{ error }}
          </div>
        </article>
      </section>

      <section class="modal-card-body">
        <b-field label="E-post">
          <b-input
            v-model="userEmail"
            type="email"
            :value="email"
            placeholder="Din e-post"
            required
          >
          </b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button class="button" type="button" @click="$parent.close()">
          Lukk
        </b-button>

        <b-button
          :loading="isLoading"
          class="button is-primary"
          @click="pressed"
          >Send</b-button
        >
      </footer>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: ["email"],
  data() {
    return {
      isLoading: false,
      userEmail: "",
      error: ""
    };
  },
  mounted() {
    if (this.email) {
      this.userEmail = this.$props.email;
    }
  },
  methods: {
    ...mapActions(["forgotPasswordAction"]),

    pressed() {
      this.isLoading = true;
      this.forgotPasswordAction({
        email: this.userEmail
      })
        .then(() => {
          this.isLoading = false;
          this.$buefy.toast.open({
            message: "Sjekk e-posten din for å tilbakestille passordet ditt!",
            type: "is-success"
          });
          this.$emit("close");
        })
        .catch(error => {
          this.isLoading = false;
          this.error = error.message;
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
