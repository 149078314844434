import Vue from "vue";
import i18n from "./i18n";
import Buefy from "buefy";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import vueCountryRegionSelect from 'vue-country-region-select'
Vue.use(vueCountryRegionSelect)

if (import.meta.NODE_ENV == "production") {
  Sentry.init({
    Vue,
    environment:
      location.hostname == "www.stadssalg.no" ? "production" : "development",
    dsn:
      "https://2bfdc402166e44f381098bbe47565c45@o589996.ingest.sentry.io/5739843",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [
          "europe-west1-stadssalg-dev.cloudfunctions.net",
          "api.stadssalg.no",
          /^\//
        ]
      })
    ],
    tracesSampleRate: 0.2
  });
}

import App from "./App.vue";
import router from "./router";
import store from "./store";
import LoadScript from "vue-plugin-load-script";
import VueCookies from "vue-cookies";
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet-vue2";

import axios from "axios";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

import numeral from "numeral";
import "numeral/locales/no";
numeral.locale("no");
import numFormat from "vue-filter-number-format";
import _ from "lodash";
import "./vee-validate";

Vue.filter("numFormat", numFormat(numeral));

/* custom nl2br prototype "filter" */
Vue.prototype.$nl2br = function(text) {
  return text ? text.replace(/\n/g, '<br>') : text;
};

/* meta tags */
import VueMeta from "vue-meta";
Vue.use(VueMeta);

/* pausible */
import Plausible from "plausible-tracker";

const { enableAutoPageviews } = Plausible({
  domain: "stadssalg.no",
  apiHost: "https://cool-sunset.stadssalg.workers.dev"
});

enableAutoPageviews();

/* phone number input */
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

Vue.use(VueTelInput, {
  preferredCountries: ["no", "se", "dk"],
  autoFormat: false,
  inputOptions: {
    placeholder: "Ditt telefonnummer",
    required: true,
    showDialCode: false
  },
  mode: "international",
  validCharactersOnly: true
});

// Import GLOBAL styles
import "./assets/scss/app.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle, faCheckSquare, faCircle, fas } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faGoogle,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";
import {
  faSearch,
  faCaretDown,
  faCaretUp,
  faLocationArrow,
  faBuilding,
  faUsers,
  faFileContract,
  faHandshake,
  faAngleLeft,
  faAngleRight,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faSearch,
  faCheckSquare,
  faEnvelope,
  faCaretDown,
  faCaretUp,
  faLocationArrow,
  faBuilding,
  faUsers,
  faFileContract,
  faHandshake,
  faAngleLeft,
  faAngleRight,
  faFacebook,
  faGoogle,
  faTwitter,
  fas //add all for now
);

Vue.component("vue-fontawesome", FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconComponent: "vue-fontawesome",
  defaultIconPack: "fas",
  defaultToastDuration: 5000,
  customIconPacks: {
    fas: {
      sizes: {
        default: "lg",
        "is-small": "1x",
        "is-medium": "2x",
        "is-large": "3x"
      },
      iconPrefix: ""
    }
  }
});

Vue.use(VueCookies);
Vue.$cookies.config("365d");

Vue.use(VueBottomSheet);

Vue.use(LoadScript);

Vue.config.productionTip = false;

var firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};

// initialize firebase
firebase.initializeApp(firebaseConfig);
/*
if (location.hostname === "localhost") {
  firebase.firestore().useEmulator("localhost", 8082);
  firebase.auth().useEmulator("http://localhost:9099/");
}
*/

/* make firebase available */
Vue.prototype.$firebase = firebase;

// make axios available
Vue.prototype.$axios = axios;

// make media path available
axios.interceptors.request.use(
  async config => {
    const firebaseToken = firebase.auth().currentUser
      ? await firebase.auth().currentUser.getIdToken()
      : null;
    if (firebaseToken) {
      config.headers.Authorization = `Bearer ${firebaseToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

/*
axios.interceptors.response.use(
  response => {
    return response;
  },
  function(error) {
    // Til StEpHeN FeNnE =): Do we need actually need this? Using axios for so many different things.
    // Prøv å logg inn med en ikke-eksisterende konto. Feilmeldingen i toast og ved login
    // pga den her "catch all"-greia.
    //router.push({ name: "Error", params: { error: error } });
    Toast.open({
      message: error.response.data.message
        ? error.response.data.message
        : error.message,
      type: "is-danger"
    });

    // Do something with response error
    //return Promise.reject(error);
    throw error;
  }
);
*/

// Variables
window.apiURL = import.meta.env.VITE_API_URL;
window.mediaPath = import.meta.env.VITE_MEDIA_PATH;

// Lodash
window._ = _;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
